import { IMAGE_PREFIX } from "../constants/urls.constants";
import { AUTO_PAY } from "../constants/route-paths.constants";
import moment from "moment";
import { paymentMessage } from "../constants/payment.constants";
import { getWhiteLogo } from "../utils/utils";

type Props = { meta: any; partnerName: String; first_name: String, dueDate: String };

const paymentTypeMap = {
  "MINIMUM-PAYMENT": "Minimum Due",
  "STATEMENT-BALANCE": "Statement Balance"
};

function AutopaySuccess(props: Props) {
  const logo_image = getWhiteLogo(props.partnerName, true);

  return (
    <div
      data-testid="autoPaySuccess"
      className={`x-sm:min-h-full dashboard-${props.partnerName} container-dashboard pt-4 lg:pt-10 pr-7 lg:pr-12 pb-10 lg:pb-0 pl-7 lg:pl-12 fixed inset-0 z-50`}
    >
      <div data-testid="logo" className="fixed">
        <img className="w-20" src={logo_image} alt="logo" />
      </div>
      <div className="x-sm:mt-20 md:mt-20 lg:mt-28 flex x-sm:flex-col md:flex-col lg:flex-row lg:h-5/6 lg:justify-between items-center">
        <div className="x-sm:w-full text-center lg:text-left lg:w-1/5 mr-12 x-sm:mr-0 lg:mr-0">
          <p className="text-xs mb-2.5 text-c1">
            {props?.meta?.schedule_created_at}
          </p>
          <p className={`text-3xl md:text-4xl ${props.partnerName === 'slm_ignite' ? 'font-din-pro' : ''}`}>
            Thanks for <br /> scheduling auto pay, {props.first_name}!
          </p>
        </div>
        <div className="w-64 md:w-3/5 lg:w-2/5 mx-auto lg:absolute lg:bottom-0 lg:left-0 lg:right-0">
          <img src={`${IMAGE_PREFIX()}/Thanks.png`} alt="success" className="lg:mx-auto w-full" />
        </div>

        <div className="mt-8 md:mt-16 lg:mt-0">
          <div className={`paymentInfo w-full sm:w-80 lg:w-96 md:mx-auto rounded-3xl px-7 lg:pl-10 lg:pr-8 py-5 paymentCard mb-10 md:mb-12 paymentCard-${props.partnerName}`}>
            <p className="text-xs-10 font-bold text-t1-color text-c1 pb-2">{paymentMessage.autopaySuccess.title.toUpperCase()}</p>
            <p className={`text-xs text-t1-color text-b10 pb-2`}>
              {`${paymentMessage.autopaySuccess.message1} `}
              {props?.dueDate}
              {`${paymentMessage.autopaySuccess.message2} `}
              {props?.meta?.bank_name}
              {`${paymentMessage.autopaySuccess.message3} `}
              {props?.meta?.account_last_4_digits}
              {`${paymentMessage.autopaySuccess.message4} `}
            </p>
            <p className={`text-3xl md:text-2xl text-t1-color ${props.partnerName === 'slm_ignite' ? 'font-din-pro' : ''}`}>
              {paymentTypeMap[props?.meta?.type]}
            </p>
          </div>
          <div className="text-center">
            <button
              data-testid="autoPaySuccessDone"
              className="h-16 w-28 focus:outline-none font-bold text-base rounded-full bg-white error-block px-8 py-3 btn-1 payment-done-btn"
              onClick={() => window.location.assign(AUTO_PAY)}
            >
              {paymentMessage.autopaySuccess.btnDone}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AutopaySuccess;
