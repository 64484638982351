import moment from "moment";
import React, { useEffect, useState } from "react";
import { FirstCharUpperCase } from "../utils/text";
import { workflow } from "../constants/workflow.constants";
import { ERROR_STATE_NOT_SUPPORTED } from "../constants/error.constants";

type Props = {
  input_field: Object;
  label: string; 
  key: string; 
  setShowButton: Function;
  defaultValue: string;
  index: number;
  setIndividualFailure: Function;
  individualFailure: boolean[];
  setAddress: Function;
  address: object;
  placeholder: string;
};

export function TextInputType(props: Props) {
  const [HasError, setHasError] = useState(false);
  const [isStateSupported, setIsStateSupported] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [value, setValue] = useState(props.defaultValue);
  const valid_states = props.input_field['picker_options'] || [];
  const valid_state_identifiers = valid_states.map((state) => state.identifier);

  let className = props.input_field['is_editable'] ? ' border-b-2' : 'opacity-30'

  let identifierToKeyMap = { 'email_address': 'email' }

  useEffect(() => {
    if (props.address[props.input_field['identifier']] === undefined) {
      let key = identifierToKeyMap[props.input_field['identifier']] ? identifierToKeyMap[props.input_field['identifier']] : props.input_field['identifier']
      props.address[key] = props.defaultValue
      props.setAddress(props.address)
    }

    if (props.defaultValue) {
      let individualFailure = props.individualFailure
      individualFailure[props.index] = false
    }

    if (props.input_field['identifier'] === 'state') {
      setIsStateSupported(valid_state_identifiers.includes(props.address['state']))
      props.setShowButton(valid_state_identifiers.includes(props.address['state']))
    }
  })

  useEffect(() => {
    if (
      ["state", "zip", "city"].includes(props.input_field["identifier"]) &&
      !props.input_field["is_editable"]
    ) {
      if (value !== props.defaultValue) {
        setValue(props.defaultValue);
      }
    }
  }, [props]);

  function handleChange(event) {
    let newValue = event.target.value;
    setValue(newValue)

    if ((newValue.length >= props.input_field['min_length'] && newValue.length <= props.input_field['max_length']) && new RegExp(props.input_field['validation_regex']).test(newValue)) {
      setHasError(false)
      props.address[props.input_field['identifier']] = newValue
      props.setAddress(props.address)
      let individualFailure = props.individualFailure
      individualFailure[props.index] = false
      props.setShowButton(!individualFailure.includes(true));
    } else {
      setHasError(true)
      let individualFailure = props.individualFailure
      individualFailure[props.index] = true
      props.setShowButton(!individualFailure.includes(true));
      setErrorMessage(workflow[props.input_field['identifier']])
    }
  }

  return (
    <div className="mb-10">
      <label className="">
        <div className="uppercase mb-2 tracking-wide field-caption">
          {FirstCharUpperCase(props.label)}
        </div>
        <input
          type="text"
          name={props.input_field["identifier"]}
          data-testid={props.input_field["identifier"]}
          max={150}
          className={`bg-transparent w-full h-12 text-3xl text-field ${className}`}
          disabled={props.input_field['is_editable'] ? false : true}
          onChange={handleChange}
          value={value}
          required
          {...props.placeholder ? {placeholder: props.placeholder} : {}}
        />
        {!isStateSupported && props.input_field["identifier"] === 'state' ? <small className="error-block" data-testid="state-not-support-error">{ERROR_STATE_NOT_SUPPORTED}</small> : null}
      </label>
      {HasError ? (
        <div>
          <p className="error-block text-xs absolute my-2">{errorMessage}</p>
        </div>
      ) : null}
    </div>
  )
}

export default TextInputType;
