import React, { useState } from "react";
import { IMAGE_BASE_URL, IMAGE_PREFIX } from "../constants/urls.constants";
import LoadingIcon from "../components/Loading";
import { DASHBOARD_PATH } from "../constants/route-paths.constants";
import { paymentMessage } from "../constants/payment.constants";
import { getWhiteLogo } from "../utils/utils";
import { ServicingStatusScreenContainer } from "../components/ServicingStatusScreenContainer";

type Props = { makePayment(); partnerName: String; type?: String };


function PaymentError(props: Props) {
  const logo_image = getWhiteLogo(props.partnerName);
  return (
    <div
      className={`x-sm:min-h-full dashboard-${props.partnerName} container-dashboard pt-3 lg:pt-10 pr-7 lg:pr-12 pb-10 lg:pb-4 pl-7 lg:pl-12 fixed inset-0 z-50`}
    >
      <div data-testid="logo" className="fixed">
        <img className="w-20" src={logo_image} alt="logo" />
      </div>
      <div
        onClick={() => window.location.assign(DASHBOARD_PATH)}
        title="Back to Dashboard"
        className={`w-8 cursor-pointer rounded-full x-sm:right-4 fixed right-12`}
      >
        <img src={`${IMAGE_PREFIX()}/${props.partnerName === 'customers_bank' ? 'close-page-white' : 'close-page'}.png`} alt="Cancel" />
      </div>
      <ServicingStatusScreenContainer>
        <div className="x-sm:w-full md:w-full lg:w-2/5 mr-12 x-sm:mr-0">
          <p className="x-sm:text-3xl text-center lg:text-left text-4xl font-semibold x-sm:pb-16 md:pb-16 lg:pb-0 text-heading-2">
            {paymentMessage.paymentError.titleLine1} <br /> {paymentMessage.paymentError.titleLine2}{" "}
            {props.type === "payments" ? "payment" : "request"}
          </p>
        </div>
        <div className="mx-auto w-64 lg:w-1/2">
          <img src={`${IMAGE_PREFIX()}/error.png`} alt="success" />
        </div>
        <div className="w-full lg:w-2/5 mt-20 lg:mt-0 ml-0 x-sm:self-auto">
          <div className="text-right x-sm:flex md:flex x-sm:flex-col md:flex-col x-sm:justify-between md:justify-between">
            <button
              className="x-sm:w-40 md:w-40 w-56 w-full h-20 x-sm:mx-auto md:mx-auto focus:outline-none font-bold text-base rounded-full bg-white error-block px-8 py-3 mb-8 btn-1 payment-done-btn"
              onClick={props.makePayment}
            >
              {paymentMessage.paymentError.btnTryAgain}
            </button>
          </div>
        </div>
      </ServicingStatusScreenContainer>
    </div>
  );
}

export default PaymentError;
