import moment from "moment";
import React, {useState} from "react";
import {FirstCharUpperCase} from "../utils/text";
import {workflow} from "../constants/workflow.constants";
import SmartStreetAutoComplete from "../components/SmartStreetAutoComplete";
import {ERROR_ADDRESS_NOT_FOUND} from "../constants/error.constants";
import {AddressType} from "../types/AddressType";

type Props = { input_field: Object; label: string; key: string; setShowButton: Function, setAddress: Function };

export function AutoCompleteInputType(props: Props) {
  const [HasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [poBoxError, setPoBoxError] = useState(false);
  const [streetApiOffline, setStreetApiOffline] = useState(false);
  const regexPO = props.input_field['banned_search_keywords'].search_texts;
  const valid_states =  props.input_field['picker_options'] || [];
  const valid_state_identifiers = valid_states.map((state) => state.identifier);

  function setAddressInputValues(addressType: AddressType) {
    let address = {
      zip_code: addressType.zipcode || '',
      city: addressType.city || '',
      state: addressType.state || '',
      address_line1: addressType.streetLine || '',
      address_line2: addressType.secondary || ''
    }

    props.setAddress(address)
  }

  function setAddress(address: AddressType, option: any) {
    let _address = address;
    if (_address && Object.keys(_address).length) {
      setAddressInputValues(_address);
      props.setShowButton(true);
    } else {
      setAddressInputValues({});
      // set_address_line1('');
      props.setShowButton(false);
    }
  }


  function onAddressSelect(option: any, inputValue: string, streetApiOffline: boolean) {
    const hasError = regexPO.some((term) => inputValue?.toLowerCase()?.includes(term));

    setStreetApiOffline(streetApiOffline);
    setHasError(streetApiOffline);
    setPoBoxError(hasError);
    if (hasError)
      return;
    setAddress(option.selectedItem, option);
  }


  return (
    <div className="mb-10">
      <label className="">
        <div className="uppercase mb-2 tracking-wide field-caption">
          {FirstCharUpperCase(props.label)}
        </div>
        <SmartStreetAutoComplete onAddressSelect={onAddressSelect}/>
      </label>
      {(HasError && !streetApiOffline && !poBoxError) ? <small data-testid="validation-error"
                                                               className="error-block">{ERROR_ADDRESS_NOT_FOUND}</small> : (!streetApiOffline && poBoxError) ?
        <small data-testid="validation-error" className="error-block">{props.input_field['banned_search_keywords'].error_message}</small> : null}
    </div>
  )
}

export default AutoCompleteInputType;
